import { ThemeOptions } from '@mui/material';
import { breakpoints } from './breakpoints';
import { general } from './general';
import { shadows } from './shadows';
import { spacing } from './spacing';
import { transitions } from './transitions';
import { typography } from './typography';
import { zIndex } from './zIndex';

export const baseThemeOptions: ThemeOptions = {
  direction: 'ltr',
  spacing,
  breakpoints,
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48,
      },
      '@media (min-width:600px)': {
        minHeight: 48,
      },
    },
  },
  shadows,
  zIndex,
  typography,
  transitions,
  general,
};
