import { useEffect } from 'react';
import appConfig from 'src/config/appConfig';
// import { useCheckMedikreditStatusQuery } from 'src/services/graphQLApi/queries/medicalAid/checkMedikreditStatus.enhanced';
import { useMediKreditPollStore } from 'src/store/mediKreditPoll/mediKreditPollStore';
import MediKreditTooltip from './BankApiOfflineTooltip';

export default function MediKreditPoll() {
  const setAvailable = useMediKreditPollStore((state) => state.setAvailable);

  // const { data } = useCheckMedikreditStatusQuery(
  //   {},
  //   {
  //     pollingInterval: appConfig.mediKreditPollingInterval,
  //   },
  // );

  // useEffect(() => {
  //   setAvailable(data?.checkMedikreditStatus.isOnline ?? false);
  // }, [data]);

  setAvailable(false);

  return <MediKreditTooltip />;
}
