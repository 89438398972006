import { Logout, MoreVert } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Icon, { svgIcons } from 'src/components/SVGIcons';
import pages from 'src/router/routes';
import { useDrawerStore } from 'src/store/drawer/drawerStore';
import { useCompanyStore } from 'src/store/company/companyStore';
import { useAuth } from '../authentication';
import ModifyProfileDrawer from './ModifyProfileDrawer';
import { useUserAuthStore } from 'src/store/userAuth/userAuthStore';

interface Props {
  handleCloseDrawer: () => void;
}

export function ViewProfile({ handleCloseDrawer }: Props) {
  const activeCompany = useCompanyStore((state) => state.activeCompany);
  const user = useUserAuthStore((state) => state.dbUser);

  // const [editUser] = useUpdateUserDisplayNameMutation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const { logout } = useAuth();
  const resetPracticeState = useCompanyStore((state) => state.resetCompanyState);

  const handleLogout = () => {
    try {
      logout();
      resetPracticeState();
    } catch (err) {
      console.error(err);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openDrawer = useDrawerStore((state) => state.openDrawer);
  const closeDrawer = useDrawerStore((state) => state.closeDrawer);

  const onEditProcedure = () => {
    openDrawer({
      drawerContent: (
        <ModifyProfileDrawer
          handleCloseDrawer={closeDrawer}
          onUpdate={(updateUserValues) => {
            if (!user) return;

            // editUser({
            //   updateUserNameDto: {
            //     id: user.id,
            //     firstName: updateUserValues.firstName,
            //     lastName: updateUserValues.lastName,
            //   },
            // })
            //   .unwrap()
            //   .then(() => {
            //     closeDrawer();
            //   })
            //   .finally(() => {});
          }}
          user={user}
        />
      ),
      drawerDirection: 'right',
    });
  };

  return (
    <div>
      <Stack
        sx={{
          padding: '24px',
          gap: '28px',
          justifyContent: 'center',
          width: '100%',
          minWidth: '500px',
          maxWidth: '500px',
        }}
      >
        <Button
          onClick={handleCloseDrawer}
          variant='outlined'
          sx={{
            justifySelf: 'start',
            padding: '10px',
            width: '44px',
            minWidth: '44px',
            height: '44px',
          }}
        >
          <Icon name='AngleDoubleIcon' fontSize='medium' />
        </Button>
        <Stack gap={0}>
          <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}>
            <Box
              style={{
                direction: 'ltr',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  direction: 'ltr',
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  sx={{ bgcolor: (theme) => theme.palette.primary.light, width: 28, height: 28 }}
                  variant='rounded'
                >
                  <Icon name='FlaskPotionIcon' fontSize='small' color='primary' />
                </Avatar>
                <Typography variant='h5' color='primary.dark'>
                  {user?.firstName ?? user?.email}
                </Typography>
              </span>
            </Box>
            <Box>
              <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} disableRipple>
                <MoreVert color='primary' />
              </IconButton>
              <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
                <MenuItem
                  onClick={() => {
                    onEditProcedure();
                    handleMenuClose();
                  }}
                >
                  <Icon name='PenIcon' sx={{ mr: 1 }} color='primary' />
                  <Typography variant='body1' color='primary' fontWeight={700}>
                    Edit Details
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <Logout
                    sx={{
                      mr: 1,
                      color: (theme) => theme.palette.black[60],
                    }}
                  />
                  <Typography variant='body1' sx={{ color: (theme) => theme.palette.black[60] }} fontWeight={700}>
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Stack>
          <Box>
            <Typography
              variant='body1'
              sx={{
                opacity: 0.6,
              }}
              color='primary.dark'
            >
              Your profile details
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ display: 'grid' }} gap={1}>
          <Typography variant='h6' color='primary.dark'>
            Contact Details
          </Typography>
          <Card variant='outlined'>
            <CardContent>
              <DetailRow icon='EmailIcon' label='Email Address' text={user?.email ?? 'N/A'} />
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ display: 'grid' }} gap={1}>
          <Typography variant='h6' color='primary.dark'>
            Account
          </Typography>
          <Card variant='outlined'>
            <CardContent sx={{ display: 'grid', gap: 1 }}>
              <DetailRow icon='EmailIcon' label='Role' text={activeCompany?.role.name ?? 'N/A'} />
              <Divider />
              <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <Button
                  variant='text'
                  color='primary'
                  size='large'
                  href={pages.auth.forgotPassword.path}
                  endIcon={<HelpOutlineIcon />}
                >
                  Change your password
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Stack>
    </div>
  );
}

function DetailRow({ icon, label, text }: { icon: svgIcons; label: string; text: string }) {
  return (
    <span
      style={{
        direction: 'ltr',
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.light, width: 28, height: 28 }} variant='rounded'>
        <Icon name={icon} fontSize='small' color='primary' />
      </Avatar>
      <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
        <Typography variant='body1' color='primary.dark' fontWeight={700}>
          {label}
        </Typography>
        <Typography variant='body1'>{text}</Typography>
      </span>
    </span>
  );
}
