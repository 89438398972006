import { Avatar, Typography, useTheme } from '@mui/material';
import React from 'react';
import Icon, { svgIcons } from 'src/components/SVGIcons';

interface DrawerHeaderWithIconProps {
  icon: svgIcons;
  title: string;
  subtitle?: string;
  infoSubtitle?: boolean;
}

const DrawerHeaderWithIcon: React.FC<DrawerHeaderWithIconProps> = ({ subtitle, icon, title, infoSubtitle = true }) => {
  const theme = useTheme();
  return (
    <div>
      <span
        style={{
          direction: 'ltr',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ bgcolor: theme.palette.primary.light, width: 28, height: 28 }} variant='rounded'>
          <Icon name={icon} fontSize='small' color='primary' />
        </Avatar>

        <Typography variant='h5' fontWeight={'800'} fontSize={'24px'} color='primary.dark'>
          {title}
        </Typography>
      </span>
      {subtitle && (
        <Typography variant='body1' color={infoSubtitle ? 'black.25' : 'primary.dark'}>
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export default DrawerHeaderWithIcon;
