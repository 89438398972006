import 'firebase/remote-config';
import {
  fetchAndActivate,
  getAll,
  getBoolean,
  getNumber,
  getRemoteConfig,
  getString,
  getValue,
  RemoteConfig,
  Value,
} from 'firebase/remote-config';
import { FeatureFlags } from 'src/models/featureFlags';
import feature_flag_defaults from '../config/featureFlags/feature_flag_defaults.json';

/**
 * Wrapper class for Remote Config.
 *
 * These methods allow you to retrieve specific configurations based on their keys and expected types.
 * This class provides a convenient way to interact with Firebase's Remote Config in your application.
 * Read the lib folder Readme for more information
 */
class RemoteConfigWrapper {
  public remoteConfig: RemoteConfig;

  constructor() {
    this.remoteConfig = getRemoteConfig();
    this.remoteConfig.defaultConfig = feature_flag_defaults;
    this.remoteConfig.settings.minimumFetchIntervalMillis = 10000;
  }

  /**
   * Initializes the Remote Config.
   */
  async initialize() {
    try {
      await fetchAndActivate(this.remoteConfig)
        .then((res) => {
          console.log('Remote config fetched and activated', res);
        })
        .catch((err) => {
          console.error('Error fetching remote config:', err);
        });
    } catch (error) {
      console.error('Error initializing remote config:', error);
    }
  }

  /**
   * Retrieves a boolean feature flag from Remote Config.
   * @param key - The key of the feature flag.
   * @returns An object containing the feature flag value.
   */
  getBoolean(key: string): FeatureFlags {
    return { [key]: { isEnabled: getBoolean(this.remoteConfig, key) } };
  }

  /**
   * Retrieves all configurations from Remote Config.
   * @returns An object containing all configurations.
   */
  getRcAllConfig(): Record<string, Value> {
    return getAll(this.remoteConfig);
  }

  /**
   * Retrieves a boolean configuration from Remote Config.
   * @param key - The key of the configuration.
   * @returns The boolean configuration value.
   */
  getRcBoolean(key: string): boolean {
    return getBoolean(this.remoteConfig, key);
  }

  /**
   * Retrieves a configuration value from Remote Config.
   * @param key - The key of the configuration.
   * @returns The configuration value.
   */
  getRcValue(key: string): Value {
    return getValue(this.remoteConfig, key);
  }

  /**
   * Retrieves a string configuration from Remote Config.
   * @param key - The key of the configuration.
   * @returns The string configuration value.
   */
  getRcString(key: string): string {
    return getString(this.remoteConfig, key);
  }

  /**
   * Retrieves a number configuration from Remote Config.
   * @param key - The key of the configuration.
   * @returns The number configuration value.
   */
  getRcNumber(key: string): number {
    return getNumber(this.remoteConfig, key);
  }
}

export default RemoteConfigWrapper;
