import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { ThemeProviderWrapper } from 'src/features/appTheme';
import { AuthProvider } from 'src/features/authentication';
import Status500 from 'src/pages/Fallbacks/Status/Status500/Status500';
import { themeNames, useThemeStore } from 'src/store/theme/themeStore';
import { AbilityProvider } from './AbilityProvider';
import { SidebarProvider } from './SidebarContext';

interface AppProvidersProps {
  children: ReactNode;
}

/**
 * AppProviders component serves as the top-level provider for the application.
 * It wraps the entire application with various context providers and essential
 * components for consistent theming, authentication, routing, and error handling.
 *
 * @component
 * @param {AppProvidersProps} children - The child components to be wrapped by the providers.
 * @return {JSX.Element} The wrapped child components with required providers and error boundaries.
 */
const AppProviders = ({ children }: AppProvidersProps) => {
  const theme = useThemeStore((state) => state.theme);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-za'>
      <ThemeProviderWrapper>
        <AuthProvider>
          <SidebarProvider>
            <AbilityProvider>
              <HelmetProvider>
                <Sentry.ErrorBoundary fallback={<Status500 resetErrorBoundary={() => window.location.reload()} />}>
                  <CssBaseline />
                  <ToastContainer theme={theme === themeNames.dark ? 'dark' : 'light'} />
                  {children}
                </Sentry.ErrorBoundary>
              </HelmetProvider>
            </AbilityProvider>
          </SidebarProvider>
        </AuthProvider>
      </ThemeProviderWrapper>
    </LocalizationProvider>
  );
};

export default AppProviders;
