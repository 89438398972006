import { wrapUseRoutes } from '@sentry/react';
import { useRoutes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import router from 'src/router/router';
import { sentryConfig } from './config/sentry';
import AppProviders from './context/AppProviders';
import { FeatureFlagGuard } from './Guards/featureFlagGuard/FeatureFlagGuard';
import StatusMaintenance from './pages/Fallbacks/Status/Maintenance/Maintenance';
import { initMonitoring } from './services/monitoringServices/initMonitoring';

initMonitoring(sentryConfig, 'nklnso1567');
const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
  const content = useSentryRoutes(router);

  return (
    <AppProviders>
      {/* Whole app in maintenance mode */}
      <FeatureFlagGuard featureFlag='app_maintenance_mode_enabled' fallback={<StatusMaintenance />} reverse>
        <div>{content}</div>
      </FeatureFlagGuard>
    </AppProviders>
  );
}

export default App;
