import { endpoints } from '../../config/endpoints';
import { appApiClient } from '../../config/axiosWrapper';
import { RestApiResponseWithData } from '@repo/debit-order-portal-shared';
import { IGetUserWithUserRolesDto } from './getCurrentUserDto';
import { useCompanyStore } from 'src/store/company/companyStore';

export const getCurrentUser = async (): Promise<RestApiResponseWithData<IGetUserWithUserRolesDto>> => {
    const res = await appApiClient.get(`v1/${endpoints.USER}/current-user`);
    useCompanyStore.getState().setCompanies(res.data.userRoles);
    useCompanyStore.getState().setActiveCompany(res.data.userRoles[0]);
    return res;
};