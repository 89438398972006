import { OutlinedTextFieldProps, TextField as MuiTextField } from '@mui/material';
export type CustomTextInputProps = OutlinedTextFieldProps;

export const CustomTextInput = ({ ...props }: CustomTextInputProps) => {
  return (
    <div>
      <MuiTextField
        InputProps={{
          notched: false,
          ...props.InputProps,
        }}
        {...props}
        InputLabelProps={{
          shrink: true,
          sx: {
            position: 'relative',
            transform: 'none',
            backgroundColor: 'transparent',
          },
          ...props.InputLabelProps,
        }}
      ></MuiTextField>
    </div>
  );
};

CustomTextInput.defaultProps = {
  variant: 'outlined',
  fullWidth: true,
};
