import { z } from 'zod';
import feature_flag_defaults from '../config/featureFlags/feature_flag_defaults.json';

// Extract the keys from the JSON file
const featureFlagNames = Object.keys(feature_flag_defaults);

// Construct the Zod enum schema based on the keys
const featureFlagEnum = z.enum([featureFlagNames[0], ...featureFlagNames.slice(1)]);

// Construct the Zod schema using the enum schema and object schema
export const featureFlagsSchema = z.record(featureFlagEnum, z.object({ isEnabled: z.boolean().default(false) }));

// Define types for FeatureFlags and FeatureFlagNames
export type FeatureFlags = z.infer<typeof featureFlagsSchema>;
export type FeatureFlagNames = (typeof featureFlagNames)[number];
export type FeatureFlagEnum = typeof featureFlagEnum;
