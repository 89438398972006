import { Box, Stack, styled, Typography } from '@mui/material';
import NProgress from 'nprogress';
import { useEffect } from 'react';

const Spinner = ({ className }: { className: string }) => {
  return <img className={className} src={'/static/images/brand/icon_light.svg'} alt='Logo' />;
};

const StyledSpinner = styled(Spinner)(() => ({
  animation: 'nfLoaderSpin 2000ms cubic-bezier(0.680, -0.550, 0.265, 1.550) 800ms infinite alternate-reverse both',

  '@keyframes nfLoaderSpin': {
    '0%': {
      transform: 'rotate(0)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

interface Props {
  message?: string;
}

function SuspenseLoader({ message }: Props) {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Stack justifyContent='center' alignItems='center' spacing={2}>
        <StyledSpinner className='logo-spinner' />
        <Box height={'50px'}>
          {' '}
          {/* This is to make sure the spinner is centered and does not jump when text from another loaders*/}
          <Typography variant='h6'>{message}</Typography>
        </Box>
      </Stack>
    </Box>
  );
}

export default SuspenseLoader;
