type IAppConfig = {
  breakMobileView: 'sm' | 'md' | 'lg';
  mediKreditPollingInterval: number;
};
//any miscellaneous config that is used across the whole app
const appConfig: IAppConfig = {
  breakMobileView: 'sm',
  mediKreditPollingInterval: 30000, //30 seconds
};

export default appConfig;
