/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { IGetUserDto } from '@repo/debit-order-portal-shared';
import { Form, Formik, FormikProps } from 'formik';

import DrawerHeaderWithIcon from 'src/components/drawers/DrawerHeaderWithIcon';
import { FormTextfield } from 'src/components/formInputs';
import Icon from 'src/components/SVGIcons';
import { IGetUserWithUserRolesDto } from 'src/services/tanstackQuery/queries/users/getCurrentUserDto';
// import { ProcedureType, UpdateUserNameDto } from 'src/services/graphQLApi/generated/generated';

interface UpdateUser extends Omit<IGetUserDto, 'id' | 'createdOn' | 'deletedOn' | 'role' | 'authServiceUid' | 'email' | 'updatedOn'> {}

function ModifyProfileDrawer({
  handleCloseDrawer,
  onUpdate,
  user,
}: {
  handleCloseDrawer: () => void;
  onUpdate?: (updateUserValues: IGetUserDto) => void;

  user?: IGetUserWithUserRolesDto | null;
}) {
  const handleSubmit = (values: UpdateUser) => {
    if (onUpdate) {
      console.log('onUpdate', values);

      // onUpdate({
      //   id: user?.id ?? '',
      //   firstName: values.firstName,
      //   lastName: values.lastName,
      //   authServiceUid: '',
      //   createdOn: new Date(),
      //   deletedOn: new Date(),
      //   email: values.email,
      //   role: {
      //     id: '',
      //     name: ''
      //   },
      //   updatedOn: new Date(),
      // });
    }
  };

  // const procedureTypeOptions = Object.values(ProcedureType).map((type) => ({
  //   children: type,
  //   value: type,
  // }));

  return (
    <Formik<UpdateUser>
      initialValues={
        user || {
          firstName: '',
          lastName: '',
        }
      }
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values }: FormikProps<UpdateUser>) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div>
              <Box
                sx={{
                  width: '500px',
                  padding: '24px',
                  gap: '31px',
                  display: 'grid',
                }}
              >
                <Button
                  onClick={handleCloseDrawer}
                  variant='outlined'
                  sx={{
                    justifySelf: 'start',
                    padding: '10px',
                    minWidth: '0px',
                  }}
                >
                  <Icon name='AngleDoubleIcon' fontSize='medium' />
                </Button>
                {/* Heading and Description */}
                <Box
                  sx={{
                    gap: '16px',
                    display: 'grid',
                  }}
                >
                  <DrawerHeaderWithIcon
                    title='Account Details'
                    icon='UserIcon'
                    subtitle='Update your account details'
                    infoSubtitle={false}
                  />
                  {/* Procedure Details */}
                  <Card variant='elevation'>
                    <CardHeader
                      title='Procedure Details'
                      titleTypographyProps={{
                        variant: 'h6',
                        color: 'primary.dark',
                      }}
                    />
                    <CardContent sx={{ paddingTop: 0 }}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <FormTextfield name='firstName' label='First Name' placeholder='Enter first name' />
                        </Grid>

                        <Grid item xs={6}>
                          <FormTextfield name='lastName' label='Last Name' placeholder='Enter last name' />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <Button
                    variant='text'
                    size='large'
                    color='error'
                    sx={{
                      width: '100%',
                      maxWidth: '200px',
                      alignContent: 'flex-end',
                      justifySelf: 'flex-end',
                      marginTop: 2,
                    }}
                  >
                    Deactivate User
                  </Button> */}
                  <Button
                    type='submit'
                    variant='contained'
                    size='large'
                    sx={{
                      width: '100%',
                      maxWidth: '200px',
                      alignContent: 'flex-end',
                      justifySelf: 'flex-end',
                      marginTop: 2,
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ModifyProfileDrawer;
