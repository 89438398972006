import { Box, Tooltip } from '@mui/material';
import { useMediKreditPollStore } from 'src/store/mediKreditPoll/mediKreditPollStore';
import Icon from '../SVGIcons';

export default function BankApiOfflineTooltip() {
  const available = useMediKreditPollStore((state) => state.available);

  if (available) {
    return null;
  }

  return (
    <Tooltip title='Bank api is offline' arrow placement='top'>
      <Box>
        <Icon
          name={'ExclamationCircleIcon'}
          color='error'
          sx={{
            animation: 'pulse 2s infinite',
            '@keyframes pulse': {
              '0%': {
                transform: 'scale(1)',
              },
              '50%': {
                transform: 'scale(1.20)',
              },
              '100%': {
                transform: 'scale(1)',
              },
            },
          }}
        />
      </Box>
    </Tooltip>
  );
}
