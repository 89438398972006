import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/context/SidebarContext';

import { Box, Divider, Drawer, styled, useTheme } from '@mui/material';

import Logo from 'src/components/LogoSign';
import appConfig from 'src/config/appConfig';
import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 8px;
`,
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            [appConfig.breakMobileView]: 'inline-block',
          },
          position: 'fixed',
          left: 0,
          boder: 1,
          borderRight: `1px solid ${theme.palette.primary.light}`,
          top: 0,
          background: theme.sidebar.background,
          boxShadow: theme.sidebar.boxShadow,
        }}
      >
        <Scrollbar>
          <Box
            mt={3}
            sx={{
              display: 'flex',
              padding: '8px 0px',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Logo width={150} />
          </Box>

          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
          borderRight: `1px solid ${theme.palette.primary.light}`,
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant='temporary'
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: theme.sidebar.background,
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  margin: 'auto',
                  width: '50%',
                }}
              >
                <Logo width={150} />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.sidebar.dividerBg,
              }}
            />
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
