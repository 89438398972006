import {
  Button,
  Divider,
  lighten,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import pages from 'src/router/routes';
import { useCompanyStore } from 'src/store/company/companyStore';
import Icon from '../SVGIcons';
import { IUserRoleWithCompanyAndRole } from 'src/services/tanstackQuery/queries/users/getCurrentUserDto';

const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&.MuiListItemButton-root': {
    backgroundColor: 'transparent',
    borderRight: 0,
    color: theme.palette.black[80],
    padding: theme.spacing(1),
    borderRadius: theme.general.borderRadius,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      borderRight: 0,
      border: `1px solid ${theme.palette.black[10]}`,
      borderRadius: theme.general.borderRadius,
      color: theme.palette.black[80],
      padding: theme.spacing(1),
    },
  },
  '&.MuiListItemButton-root.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
    borderRight: 0,
    border: `1px solid ${theme.palette.black[10]}`,
    borderRadius: theme.general.borderRadius,
    color: theme.palette.black[80],
    padding: theme.spacing(1),
  },
  '&.MuiButtonBase-root-MuiListItemButton-root:hover': {
    backgroundColor: theme.palette.primary.light,
    borderRight: 0,
    border: `1px solid ${theme.palette.black[10]}`,
    borderRadius: theme.general.borderRadius,
    color: theme.palette.black[80],
    padding: theme.spacing(1),
  },
}));

const CustomListItemIconButton = styled(ListItemIcon)(({ theme }) => ({
  '&.MuiListItemIcon-root': {
    minWidth: '24px !important',
    color: theme.palette.primary.main,
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
}));

export default function TenantSelect({ practices }: { practices: IUserRoleWithCompanyAndRole[] }) {
  const navigate = useNavigate();
  const setActivePractice = useCompanyStore((state) => state.setActiveCompany);
  const activeCompany = useCompanyStore((state) => state.activeCompany);

  const handleChange = (event: SelectChangeEvent) => {
    setActivePractice(practices.find((p) => p.company.name === (event.target.value as string)) ?? practices[0]);
  };

  interface TenantDisplayProps {
    tenant: string;
  }
  const TenantDisplay = ({ tenant }: TenantDisplayProps) => {
    return (
      <Box>
        <Typography variant='body1' fontWeight={700}>
          {tenant}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
      }}
    >
      <FormControl fullWidth>
        <Select
          sx={{
            boxShadow: (theme) => '0px 2px 4px 0px' + lighten(theme.palette.primary.main, 0.1),
            maxHeight: '44px',
          }}
          labelId='tenant-simple-select-label'
          id='tenant-simple-select'
          value={activeCompany?.company?.name ?? ''}
          onChange={handleChange}
          renderValue={(p: string) => <TenantDisplay tenant={p} />}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiMenu-list': {
                  padding: '10px',
                  gap: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiDivider-root': {
                    marginTop: '0px',
                    marginBottom: '0px',
                  },
                },
                '& .MuiMenuItem-root': {
                  padding: '0px',
                  borderRadius: (theme) => theme.general.borderRadius,
                },
                '& .MuiMenuItem-root.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '& .MuiMenuItem-root:hover': {
                  backgroundColor: 'transparent',
                },
                '& .MuiMenuItem-root.Mui-selected:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },
          }}
        >
          <ListItemText
            sx={{ color: (theme) => theme.palette.black[80] }}
            primaryTypographyProps={{
              sx: {
                fontWeight: 700,
                padding: '4px 0px 4px 10px',
              },
            }}
            primary={'Companies'}
          />
          {practices?.map((item) => (
            <MenuItem key={`practice-${item.company?.id}`} value={item.company?.name ?? ''} disableRipple>
              <CustomListItemButton selected={activeCompany?.company?.id === item.company?.id}>
                <ListItemIcon
                  sx={{
                    minWidth: '40px',
                  }}
                >
                  <Icon name='ProfileIcon' />
                </ListItemIcon>
                <ListItemText primary={item.company?.name} /*secondary={item.practiceLocation?.city}*/ />
                <Tooltip title='Manage Details' arrow>
                  <CustomListItemIconButton
                    onClick={() => navigate(pages.settings.path, { state: { practice: item } })}
                  >
                    <Icon name='GearIcon' />
                  </CustomListItemIconButton>
                </Tooltip>
              </CustomListItemButton>
            </MenuItem>
          ))}

          <Divider />
          <Button
            variant='text'
            sx={{
              padding: '8px',
              alignContent: 'center',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: (theme) => theme.palette.primary.main,
              },
            }}
            disableRipple
            onClick={() => {
              navigate(pages.settings.path, { state: { practice: null } });
            }}
            endIcon={
              <Icon
                sx={{
                  minWidth: '24px !important',
                  color: (theme) => theme.palette.primary.main,
                  opacity: 0.6,
                  '&:hover': {
                    opacity: 1,
                  },
                }}
                name='PlusIcon'
              />
            }
          >
            <Typography variant='body1' fontWeight={700} color={(theme) => theme.palette.text.primary}>
              Add Company
            </Typography>
          </Button>
        </Select>
      </FormControl>
    </Box>
  );
}
