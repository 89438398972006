import { TextField, TextFieldProps } from '@mui/material';
import { FieldHookConfig, useField } from 'formik';

export const FormTextfield = (
  props: FieldHookConfig<string> &
    Omit<TextFieldProps, 'variant'> & { showErrorText?: boolean; maxLength?: number; numbersField?: boolean },
) => {
  const { showErrorText, ...rest } = props;
  const [field, meta] = useField(props.name);

  const errorText = meta.error && meta.touched;

  return (
    <>
      <TextField
        {...field}
        {...rest}
        {...(props.numbersField && { ...numberProps })}
        inputProps={{
          maxLength: props.maxLength,
        }}
        variant='outlined'
        size='small'
        fullWidth
        label={props.label}
        placeholder={props.placeholder}
        required={props.required}
        error={!!errorText}
        helperText={showErrorText ? errorText : undefined}
        // onChange = {handleChange}
      />
    </>
  );
};

export const numberProps: TextFieldProps = {
  type: 'number',
  InputProps: {
    onWheel: (event: React.WheelEvent<HTMLInputElement>) => (event.target as HTMLElement).blur(),
    inputProps: { min: 0, step: 1 },
  },
  sx: {
    '& input[type=number]': {
      '-MozAppearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-WebkitAppearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-WebkitAppearance': 'none',
      margin: 0,
    },
  },
};
