/**
 * This file defines the PureLightTheme, which is a custom light theme for the application.
 * It imports necessary dependencies from the base theme and defines the theme object with various style overrides options.
 * The theme is used to customize the appearance of components in the application.
 * When adding new components, please use the theme configuration options to customize the appearance of the component.
 */

import '@mui/lab/themeAugmentation';
import { createTheme, darken, lighten, ThemeOptions } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { baseThemeOptions } from './foundations/baseThemeOptions';
import { lightModePalette } from './foundations/palette';

let theme = createTheme(baseThemeOptions, {
  palette: lightModePalette,
} as ThemeOptions) as Theme;

theme = createTheme(theme, {
  sidebar: {
    background: theme.palette.background.paper,
    textColor: theme.palette.text.primary,
    dividerBg: theme.palette.trueWhite[10],
    menuItemColor: theme.palette.text.primary,
    menuItemColorActive: theme.palette.primary.main,
    menuItemBg: theme.palette.background.paper,
    menuItemBgActive: theme.palette.background.paper,
    menuItemIconColor: lighten(theme.palette.text.primary, 0.3),
    menuItemIconColorActive: theme.palette.primary.main,
    menuItemHeadingColor: darken(theme.palette.text.primary, 0.3),
    boxShadow: `0px 2px 4px 0px' ${lighten(theme.palette.primary.main, 0.1)}`,
    width: theme.spacing(32),
  },
  header: {
    height: theme.spacing(10),
    background: theme.palette.background.default,
    boxShadow: 'none',
    textColor: theme.palette.text.primary,
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'transparent',
          },
        },
        iconOutlined: {},
        icon: {
          color: theme.palette.primary.main,
          opacity: 0.6,
          top: 'calc(50% - 12px)',
        },
      },
    },

    // Blur backdrop on dropdown menu or select component
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backgroundColor: alpha(darken(theme.palette.primaryAlt.main, 0.4), 0.2),
          // backdropFilter: 'blur(2px)',

          '&.MuiBackdrop-invisible': {
            // backgroundColor: 'transparent',
            // backdropFilter: 'blur(2px)',
          },
        },
      },
    },
    // Add Label on textfields above the input
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          fontWeight: 'bold',
          transform: 'none',
          backgroundColor: 'transparent',
          alignSelf: 'flex-start',
          position: 'relative',
          padding: '4px 0px 4px 10px',
        },

        formControl: {
          // applied to texfield and select components
          marginBottom: 4,
          color: theme.palette.black[80],
          '&.Mui-focused': {
            color: theme.palette.black[80],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.black[10],
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          '&:hover ': {
            background: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.light,
          },

          '&.Mui-focused': {
            background: theme.palette.white[100],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
          '&.Mui-disabled': {
            background: theme.palette.white[100],
          },

          '&.Mui-error': {
            background: theme.palette.white[100],
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main,
          },
        },
        notchedOutline: {
          borderWidth: 2,
          borderColor: theme.palette.primary.light,
        },

        /** Styles applied to the root element if `endAdornment` is provided. */
        adornedEnd: {
          paddingRight: 10,
        },
        /** Styles applied to the root element if `startAdornment` is provided. */
        adornedStart: {
          paddingRight: 10,
        },
        /** Styles applied to the input element. */
        input: {
          ...theme.typography.body1,
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          color: '#99A1B3',
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        primary: {
          ...theme.typography.body1,
          fontWeight: 'bold',
          color: 'inherit',
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '36px',
          color: 'inherit',
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          transition: 'all .2s',

          '&:hover': {
            background: 'transparent',
            color: theme.palette.primary.main,
          },
          '&.Mui-selected': {
            background: 'transparent',
            color: theme.palette.primary.dark,
            borderRight: '2px solid ' + theme.palette.secondary.main,
            '&:hover': {
              background: 'transparent',
              color: theme.palette.primary.dark,
            },
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        colorSecondary: {
          // background: theme.palette.black[5],
          color: theme.palette.black[100],

          '&:hover': {
            background: theme.palette.black[10],
          },
        },
        deleteIcon: {
          color: theme.palette.error.light,

          '&:hover': {
            color: theme.palette.error.main,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',

          '&.Mui-expanded': {
            margin: 0,
          },
          '&::before': {
            display: 'none',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 'bold',
        },
        colorDefault: {
          color: theme.palette.white[100],
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        avatar: {
          fontSize: 13,
          fontWeight: 'bold',
          '&:first-of-type': {
            border: 0,
            background: 'transparent',
          },
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderWidth: 2,
          textTransform: 'none',
          '&:hover': {
            borderWidth: 2,
          },
        },
        textPrimary: {
          color: theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.primary.dark,
            background: 'transparent',
          },
          '&:not(:hover) .MuiButton-startIcon, &:not(:hover) .MuiButton-endIcon': {
            opacity: 0.6,
            color: 'inherit',
          },
        },
        textError: {
          color: theme.palette.error.main,
          '&:hover': {
            color: theme.palette.primary.dark,
            background: 'transparent',
          },
          '&:not(:hover) .MuiButton-startIcon, &:not(:hover) .MuiButton-endIcon': {
            opacity: 0.6,
            color: 'inherit',
          },
        },
        containedPrimary: {
          background: theme.palette.primary.main,
          color: theme.palette.white[100],
          boxShadow: '0px 2px 10px 0px rgba(47, 49, 146, 0.33)',
          border: '1px solid ' + theme.palette.white[10],
          '&:hover': {
            border: '1px solid ' + theme.palette.white[25],
            background: theme.palette.primary.main,
            boxShadow: '0px 4px 12px 0px rgba(47, 49, 146, 0.36)',
            opacity: 0.8,
          },
          '&.Mui-disabled': {
            border: `1px solid ${theme.palette.white[10]}`,
            background: theme.palette.black[10],
            color: theme.palette.common.white,
          },
          '&:not(:hover) .MuiButton-startIcon, &:not(:hover) .MuiButton-endIcon': {
            color: theme.palette.white[60],
          },
        },
        outlinedPrimary: {
          borderColor: theme.palette.primary.light,
          borderWidth: 2,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
          },
          '&.Mui-disabled': {
            opacity: 0.3,
            border: `2px solid ${theme.palette.black[10]}`,
            color: theme.palette.black[60],
          },
          '&:not(:hover) .MuiButton-startIcon, &:not(:hover) .MuiButton-endIcon': {
            opacity: 0.6,
            color: 'inherit',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
      styleOverrides: {
        root: {},
      },
    },

    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: 6,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
            transform: 'none',
          },
          '& .MuiSlider-valueLabel': {
            borderRadius: 6,
            background: theme.palette.black[100],
            color: theme.palette.white[100],
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginLeft: 8,
          marginRight: 8,
          fontWeight: 'bold',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          width: '100%',
          height: '100%',
        },
        body: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          flex: 1,
        },
        '#root': {
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        },
        html: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },
        '.child-popover .MuiPaper-root .MuiList-root': {
          flexDirection: 'column',
        },
        '#nprogress': {
          pointerEvents: 'none',
        },
        '#nprogress .bar': {
          background: theme.palette.primary.light,
        },
        '#nprogress .spinner-icon': {
          borderTopColor: theme.palette.primary.light,
          borderLeftColor: theme.palette.primary.light,
        },
        '#nprogress .peg': {
          boxShadow: '0 0 15px ' + theme.palette.primary.light + ', 0 0 8px' + theme.palette.primary.light,
        },
        ':root': {
          '--swiper-theme-color': theme.palette.primary.main,
        },
        code: {
          background: theme.palette.info.light,
          color: theme.palette.info.dark,
          borderRadius: 4,
          padding: 4,
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.8)',
            opacity: 0,
          },
        },
        '@keyframes float': {
          '0%': {
            transform: 'translate(0%, 0%)',
          },
          '100%': {
            transform: 'translate(3%, 3%)',
          },
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: -5,
          marginBottom: -5,
        },
        title: {
          fontSize: 15,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'elevation',
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          // padding: 10,
        },
        elevation0: {
          boxShadow: 'none',
        },
        elevation: {
          border: '1px solid ' + theme.palette.primary.light,
          boxShadow: theme.palette.shadows.card,
        },
        elevation2: {
          boxShadow: theme.palette.shadows.cardSm,
        },
        elevation24: {
          boxShadow: theme.palette.shadows.cardLg,
        },
        outlined: {
          borderColor: theme.palette.primary.light,
        },
      },
    },

    MuiTabs: {
      defaultProps: {
        variant: 'standard',
        centered: true,
      },
      styleOverrides: {
        root: {
          height: 44,
          minHeight: 44,
          overflow: 'visible',
        },
        indicator: {
          height: 40,
          minHeight: 40,
          borderRadius: 8,
          bottom: 2,
          border: '1px solid ' + theme.palette.primary.main,
          boxShadow: '0px 2px 10px ' + theme.palette.primary.light,
        },
        flexContainer: {
          justifyContent: 'space-evenly',
        },

        scrollableX: {
          overflow: 'visible !important',
        },
      },
    },
    MuiTab: {
      defaultProps: {},
      styleOverrides: {
        root: {
          height: 44,
          minHeight: 44,
          fontWeight: 700,
          fontSize: '14px',
          textTransform: 'none',
          color: theme.palette.primary.main,
          borderRadius: 8,
          transition: 'color .2s',

          '&.Mui-selected, &.Mui-selected:hover': {
            color: theme.palette.white[100],
            zIndex: 5,
          },
          '&:hover': {
            color: theme.palette.black[100],
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 1,
        },
        root: {
          '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            right: 14,
          },
        },
        clearIndicator: {
          background: theme.palette.error.light,
          color: theme.palette.error.main,
          marginRight: 8,

          '&:hover': {
            background: theme.palette.error.light,
            color: theme.palette.error.dark,
          },
        },
        popupIndicator: {
          color: theme.palette.black[50],

          '&:hover': {
            background: theme.palette.primary.light,
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          '& .MuiIconButton-root': {
            padding: 8,
          },
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '0 !important',
          padding: '0 !important',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          background: theme.palette.black[5],
        },
        root: {
          transition: 'background-color .2s',

          '&.MuiTableRow-hover:hover': {
            backgroundColor: theme.palette.black[5],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: theme.palette.black[10],
          fontSize: 14,
        },
        head: {
          textTransform: 'uppercase',
          fontSize: 13,
          fontWeight: 'bold',
          color: theme.palette.black[60],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          lineHeight: 1.5,
          fontSize: 14,
        },
        // standardInfo: {
        //   color: theme.palette.info.main,
        // },
        // action: {
        //   color: theme.palette.black[60],
        // },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          margin: 0,
          zIndex: 5,
          position: 'absolute',
          top: '50%',
          marginTop: -6,
          left: -6,
        },
        outlined: {
          backgroundColor: theme.palette.white[100],
          boxShadow: '0 0 0 6px ' + theme.palette.white[100],
        },
        outlinedPrimary: {
          backgroundColor: theme.palette.white[100],
          boxShadow: '0 0 0 6px ' + theme.palette.white[100],
        },
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          position: 'absolute',
          height: '100%',
          top: 0,
          borderRadius: 50,
          backgroundColor: theme.palette.black[10],
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          minHeight: 0,
          padding: '8px 0',

          '&:before': {
            display: 'none',
          },
        },
        missingOppositeContent: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.common.white,
          padding: '12px',
          color: theme.palette.primary.dark,
          ...theme.typography.body1,
        },
        arrow: {
          color: theme.palette.common.white,
          alignContent: 'flex-end',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: 33,
          overflow: 'visible',

          '& .MuiButtonBase-root': {
            position: 'absolute',
            padding: 6,
            transition: 'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
          '& .MuiIconButton-root': {
            borderRadius: 100,
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            opacity: 0.3,
          },
        },
        thumb: {
          border: '1px solid ' + theme.palette.black[25],
          boxShadow: '0px 9px 14px ' + theme.palette.black[10] + ', 0px 2px 2px ' + theme.palette.black[10],
        },
        track: {
          backgroundColor: theme.palette.black[5],
          border: '1px solid ' + theme.palette.black[10],
          boxShadow: 'inset 0px 1px 1px ' + theme.palette.black[10],
          opacity: 1,
        },
        colorPrimary: {
          '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.white[100],
          },

          '&.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          paddingTop: 20,
          paddingBottom: 20,
          background: theme.palette.black[5],
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.MuiStepIcon-completed': {
            color: theme.palette.success.main,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div',
          button: 'div',
        },
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: 4,
        },
        paragraph: {
          fontSize: 17,
          lineHeight: 1.7,
        },
      },
    },
  },
} as ThemeOptions) as Theme;

export { theme as PureLightTheme };
