import { ZIndexOptions } from '@mui/material/styles/zIndex';

// introduce new zIndex variant to be used
// declare module '@mui/material/styles' {
//   interface ZIndex {
//     floating: number;
//   }
//   interface ZIndexOptions {
//     floating: number;
//   }
// }

export const zIndex: ZIndexOptions = {
  mobileStepper: 1000,
  fab: 1050,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
};
