import { create } from 'zustand';

export interface MediKreditPollState {
  available: boolean;
  setAvailable: (available: boolean) => void;
}

export const useMediKreditPollStore = create<MediKreditPollState>()((set) => ({
  available: true,
  setAvailable: (available: boolean) => set({ available: available }),
}));
