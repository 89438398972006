import { z, ZodFormattedError } from 'zod';

export const clientSchema = z.object({
  VITE_BASE_API_URL: z.string().min(1),
  VITE_APP_BASE_URL: z.string().min(1),
  VITE_APP_NAME: z.string().min(1),
  VITE_DEPLOYMENT_ENV: z.string().min(1),
  VITE_REDIRECT_SUCCESS: z.string().min(1),
  VITE_REDIRECT_FAILURE: z.string().min(1),
  VITE_REDIRECT_CANCEL: z.string().min(1),
  VITE_SENTRY_DSN: z.string().min(1),
  VITE_FIREBASE_API_KEY: z.string().min(1),
  VITE_FIREBASE_AUTH_DOMAIN: z.string().min(1),
  VITE_FIREBASE_PROJECT_ID: z.string().min(1),
  VITE_FIREBASE_STORAGE_BUCKET: z.string().min(1),
  VITE_FIREBASE_MESSAGING_SENDER_ID: z.string().min(1),
  VITE_FIREBASE_APP_ID: z.string().min(1),
  VITE_VERSION: z.string().min(1),
});

console.log('a', import.meta.env);
const _clientEnv = clientSchema.safeParse(import.meta.env);

export const formatErrors = (errors: ZodFormattedError<Map<string, string>, string>) =>
  Object.entries(errors)
    .map(([name, value]) => {
      if (value && '_errors' in value) return `${name}: ${value._errors.join(', ')}\n`;
    })
    .filter(Boolean);

if (!_clientEnv.success) {
  console.error('❌ Invalid environment variables:\n', ...formatErrors(_clientEnv.error.format()));
  throw new Error('Invalid environment variables');
}

export const env = _clientEnv.data;
