import { defineAbility } from '@casl/ability';

//TODO: At a later stage this needs to be more dynamic. The abilities will be in JSON form saved on the database.
//For now it is hardcoded, but in the future there will be custom roles with different abilities.

/**
 * Define user abilities based on their user type.
 * @param userType - The type of the user, e.g., 'admin' or 'user'.
 * @returns {Ability} - An instance of the defined abilities for the user.
 */
export default function defineAbilityFor(userType: string) {
  return defineAbility((can, cannot) => {
    if (userType === 'Admin') {
      //###### pages ######
      //The below defines top level pages the user has access to
      can('visit', PAGES.SCHEDULER);
      can('visit', PAGES.PATIENTS);
      can('visit', PAGES.INVOICES);
      can('visit', PAGES.INVENTORY);
      can('visit', PAGES.REPORTS);
      can('visit', PAGES.MANAGE_PRACTICE);
      can('visit', PAGES.TARIFF_DB);
      can('visit', PAGES.ICD10_DB);
      can('visit', PAGES.NAPPI_DB);
      can('visit', PAGES.PRODUCTS);

      //###### features ######
      //This will be sub-features that exist (it can be nested)
      // add sub casl abilities here
      can('create', 'users');
      can('read', 'users');
      can('update', 'users');
      can('delete', 'users');

      //scheduler
      //patients
      //invoices
      //inventory
      //reports
      //managePractice
    } else if (userType === 'Accountant') {
      //###### pages ######
      //The below defines top level pages the user has access to
      can('visit', PAGES.SCHEDULER);
      can('visit', PAGES.PATIENTS);
      can('visit', PAGES.INVOICES);
      can('visit', PAGES.INVENTORY);
      can('visit', PAGES.REPORTS);
      can('visit', PAGES.MANAGE_PRACTICE);
      can('visit', PAGES.TARIFF_DB);
      can('visit', PAGES.ICD10_DB);
      can('visit', PAGES.NAPPI_DB);

      //###### features ######
      //This will be sub-features that exist (it can be nested)
      //scheduler
      //patients
      //invoices
      //inventory
      //reports
      //managePractice
    } else if (userType === 'FrontDeskStaff') {
      //###### pages ######
      //The below defines top level pages the user has access to
      can('visit', PAGES.SCHEDULER);
      cannot('visit', PAGES.PATIENTS); // TEST SO THAT WE CAN CHECK IF IT WORKS
      can('visit', PAGES.INVOICES);
      can('visit', PAGES.INVENTORY);
      can('visit', PAGES.REPORTS);
      can('visit', PAGES.MANAGE_PRACTICE);
      can('visit', PAGES.TARIFF_DB);
      can('visit', PAGES.ICD10_DB);
      can('visit', PAGES.NAPPI_DB);

      //###### features ######
      //This will be sub-features that exist (it can be nested)
      //scheduler
      //patients
      //invoices
      //inventory
      //reports
      //managePractice
    } else if (userType === 'Viewer') {
      //###### pages ######
      //The below defines top level pages the user has access to
      can('visit', PAGES.SCHEDULER);
      can('visit', PAGES.PATIENTS);
      can('visit', PAGES.INVOICES);
      can('visit', PAGES.INVENTORY);
      can('visit', PAGES.REPORTS);
      can('visit', PAGES.MANAGE_PRACTICE);
      can('visit', PAGES.TARIFF_DB);
      can('visit', PAGES.ICD10_DB);
      can('visit', PAGES.NAPPI_DB);

      //###### features ######
      //This will be sub-features that exist (it can be nested)
      //scheduler
      //patients
      //invoices
      //inventory
      //reports
      //managePractice
    } else {
      //###### pages ######
      //The below defines top level pages the user has access to
      cannot('visit', PAGES.SCHEDULER);
      cannot('visit', PAGES.PATIENTS);
      cannot('visit', PAGES.INVOICES);
      cannot('visit', PAGES.INVENTORY);
      cannot('visit', PAGES.REPORTS);
      cannot('visit', PAGES.MANAGE_PRACTICE);
      cannot('visit', PAGES.TARIFF_DB);
      cannot('visit', PAGES.ICD10_DB);
      cannot('visit', PAGES.NAPPI_DB);

      //###### features ######

      //This will be sub-features that exist (it can be nested)
      //scheduler
      //patients
      //invoices
      //inventory
      //reports
      //managePractice
    }
    //Add other roles here
  });
}

export const enum PAGES {
  SCHEDULER = 'scheduler',
  PRODUCTS = 'products',
  PATIENTS = 'patients',
  INVOICES = 'invoices',
  INVENTORY = 'inventory',
  REPORTS = 'reports',
  MANAGE_PRACTICE = 'managePractice',
  TARIFF_DB = 'tariffDb',
  ICD10_DB = 'icd10Db',
  NAPPI_DB = 'nappiDb',
}
