declare module '@mui/material/styles' {
  interface Theme {
    general: {
      reactFrameworkColor: string;
      borderRadiusSm: string;
      borderRadius: string;
      borderRadiusLg: string;
    };
  }
  interface ThemeOptions {
    general?: {
      reactFrameworkColor: string;
      borderRadiusSm: string;
      borderRadius: string;
      borderRadiusLg: string;
    };
  }
}

export const general = {
  reactFrameworkColor: '#00D8FF',
  borderRadiusSm: '8px',
  borderRadius: '12px',
  borderRadiusLg: '20px',
};
