// import Box from '@mui/material/Box';
import { Box, DialogContent, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useDialogStore } from 'src/store/dialog/dialogStore';
import { CloseRounded } from '@mui/icons-material';

// Dialog component
export const BaseDialog: React.FC = () => {
  const isDialogOpen = useDialogStore((state) => state.isDialogOpen);
  const closeDialog = useDialogStore((state) => state.closeDialog);
  const dialogContent = useDialogStore((state) => state.dialogContent);

  return (
    <Dialog
      open={isDialogOpen}
      onClose={closeDialog}
      maxWidth="md" // Adjust this if needed
      fullWidth // Ensures it takes up full width based on maxWidth
      PaperProps={{
        sx: {
          minHeight: '70vh', // Set the minimum height
          minWidth: '50vw',  // Set the minimum width
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end" p={1}>
        <IconButton aria-label="close" onClick={closeDialog} size="small">
          <CloseRounded />
        </IconButton>
      </Box>
      <DialogContent sx={{ p: 2, height: '100%' }}>
        {dialogContent}
      </DialogContent>
    </Dialog>
  );
};
