import Drawer from '@mui/material/Drawer';
import React from 'react';
import { useDrawerStore } from 'src/store/drawer/drawerStore';

// Drawer component
export const BaseDrawer: React.FC = () => {
  const isDrawerOpen = useDrawerStore((state) => state.isDrawerOpen);
  const closeDrawer = useDrawerStore((state) => state.closeDrawer);
  const drawerDirection = useDrawerStore((state) => state.drawerDirection);
  const drawerContent = useDrawerStore((state) => state.drawerContent);

  const drawerIndex = (drawerContent?.length ?? 1) - 1;

  return (
    <Drawer
      anchor={drawerDirection ? drawerDirection[drawerIndex] : 'right'}
      open={isDrawerOpen ? isDrawerOpen[drawerIndex] : false}
      onClose={closeDrawer}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 2,
      }}
    >
      {drawerContent ? drawerContent[drawerIndex] : null}
    </Drawer>
  );
};
