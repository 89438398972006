import { InputBaseComponentProps, TextField } from '@mui/material';
import { memo, useLayoutEffect, useRef } from 'react';
import usePrevious from '../../hooks/usePrevious';

export interface SingleOTPInputProps extends InputBaseComponentProps {
  focus?: boolean;
  error: boolean;
}

export function SingleOTPInputComponent({ focus, error, autoFocus, ...props }: SingleOTPInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <TextField
      error={error}
      inputProps={{
        ref: inputRef,
        style: {
          textAlign: 'center',
          textDecoration: 'bold',
        },
        ...props,
      }}
      sx={{
        width: '50px',
        mr: 1,
      }}
    />
  );
}

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;
