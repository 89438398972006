import { alpha, darken, lighten, PaletteOptions } from '@mui/material';
import { darkModeColors, lightModeColors } from '../themeColors';

declare module '@mui/material/styles' {
  interface Palette {
    primaryAlt: Palette['primary'];
    tertiary1: Palette['primary'];
    tertiary2: Palette['primary'];
    tertiary3: Palette['primary'];
    white: {
      5: string;
      10: string;
      25: string;
      50: string;
      60: string;
      80: string;
      100: string;
    };
    trueWhite: {
      5: string;
      10: string;
      30: string;
      50: string;
      70: string;
      100: string;
    };
    black: {
      5: string;
      10: string;
      25: string;
      50: string;
      60: string;
      80: string;
      100: string;
    };
    shadows: {
      success: string;
      error: string;
      info: string;
      primary: string;
      warning: string;
      card: string;
      cardSm: string;
      cardLg: string;
    };
  }

  interface PaletteOptions {
    primaryAlt: PaletteOptions['primary'];
    tertiary1: PaletteOptions['primary'];
    tertiary2: PaletteOptions['primary'];
    tertiary3: PaletteOptions['primary'];
    white: {
      5: string;
      10: string;
      25: string;
      50: string;
      60: string;
      80: string;
      100: string;
    };
    trueWhite: {
      5: string;
      10: string;
      30: string;
      50: string;
      70: string;
      100: string;
    };
    black: {
      5: string;
      10: string;
      25: string;
      50: string;
      60: string;
      80: string;
      100: string;
    };
    shadows: {
      success: string;
      error: string;
      info: string;
      primary: string;
      warning: string;
      card: string;
      cardSm: string;
      cardLg: string;
    };
  }
}

export const lightModePalette: PaletteOptions = {
  mode: 'light',
  common: {
    black: lightModeColors.black,
    white: lightModeColors.white,
  },
  primary: {
    light: lightModeColors.primary.tint,
    main: lightModeColors.primary.main,
    dark: lightModeColors.primary.shade,
    contrastText: lightModeColors.white,
  },
  primaryAlt: {
    main: lightModeColors.primaryAlt.main,
    light: lightModeColors.primaryAlt.tint,
    dark: lightModeColors.primaryAlt.shade,
    contrastText: lightModeColors.white,
  },
  secondary: {
    light: lightModeColors.secondary.tint,
    main: lightModeColors.secondary.main,
    dark: lightModeColors.secondary.shade,
    contrastText: lightModeColors.white,
  },
  tertiary1: {
    main: lightModeColors.tertiary1.main,
    light: lightModeColors.tertiary1.tint,
    dark: lightModeColors.tertiary1.shade,
    contrastText: lightModeColors.white,
  },
  tertiary2: {
    main: lightModeColors.tertiary2.main,
    light: lightModeColors.tertiary2.tint,
    dark: lightModeColors.tertiary2.shade,
    contrastText: lightModeColors.white,
  },
  tertiary3: {
    main: lightModeColors.tertiary3.main,
    light: lightModeColors.tertiary3.tint,
    dark: lightModeColors.tertiary3.shade,
    contrastText: lightModeColors.white,
  },
  error: {
    light: lighten(lightModeColors.error, 0.85),
    main: lightModeColors.error,
    dark: darken(lightModeColors.error, 0.2),
    contrastText: lightModeColors.white,
  },
  success: {
    light: lighten(lightModeColors.success, 0.85),
    main: lightModeColors.success,
    dark: darken(lightModeColors.success, 0.2),
    contrastText: lightModeColors.white,
  },
  info: {
    light: lighten(lightModeColors.info, 0.85),
    main: lightModeColors.info,
    dark: darken(lightModeColors.info, 0.2),
    contrastText: lightModeColors.white,
  },
  warning: {
    light: lighten(lightModeColors.warning, 0.85),
    main: lightModeColors.warning,
    dark: darken(lightModeColors.warning, 0.2),
    contrastText: lightModeColors.white,
  },
  text: {
    primary: lightModeColors.black,
    secondary: lighten(lightModeColors.primary.shade, 0.4),
    disabled: lighten(lightModeColors.black, 0.6),
  },
  background: {
    paper: lightModeColors.white,
    default: lightModeColors.uiSurface,
  },
  divider: lighten(lightModeColors.black, 0.1),
  action: {
    // active: lightModeColors.black,
    hover: lightModeColors.primary.tint,
    hoverOpacity: 0.1,
    selected: lighten(lightModeColors.black, 0.8),
    selectedOpacity: 0.1,
    disabled: lighten(lightModeColors.black, 0.6),
    disabledBackground: lighten(lightModeColors.black, 0.1),
    disabledOpacity: 0.38,
    // focus: lighten(lightModeColors.black, 0.1),
    // focusOpacity: 0.05,
    activatedOpacity: 0.12,
  },
  white: {
    5: alpha(lightModeColors.white, 0.05),
    10: alpha(lightModeColors.white, 0.1),
    25: alpha(lightModeColors.white, 0.25),
    50: alpha(lightModeColors.white, 0.5),
    60: alpha(lightModeColors.white, 0.6),
    80: alpha(lightModeColors.white, 0.8),
    100: lightModeColors.white,
  },
  trueWhite: {
    5: alpha(lightModeColors.trueWhite, 0.02),
    10: alpha(lightModeColors.white, 0.1),
    30: alpha(lightModeColors.white, 0.3),
    50: alpha(lightModeColors.white, 0.5),
    70: alpha(lightModeColors.white, 0.7),
    100: lightModeColors.white,
  },

  black: {
    5: alpha(lightModeColors.black, 0.05),
    10: alpha(lightModeColors.black, 0.1),
    25: alpha(lightModeColors.black, 0.25),
    50: alpha(lightModeColors.black, 0.5),
    60: alpha(lightModeColors.black, 0.6),
    80: alpha(lightModeColors.black, 0.8),
    100: lightModeColors.black,
  },
  grey: {
    50: '#5F6D7E',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  contrastThreshold: 3,
  tonalOffset: 0.5,
  shadows: {
    success: '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
    error: '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
    info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
    primary: '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
    warning: '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
    card: '0px 2px 4px 0px rgba(47, 49, 146, 0.10)',
    cardSm: '0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
    cardLg: '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)',
  },
};
export const darkModePalette: PaletteOptions = {
  mode: 'dark',
  common: {
    black: darkModeColors.black,
    white: darkModeColors.white,
  },
  primary: {
    light: darkModeColors.primary.tint,
    main: darkModeColors.primary.main,
    dark: darkModeColors.primary.shade,
    contrastText: darkModeColors.white,
  },
  primaryAlt: {
    main: darkModeColors.primaryAlt.main,
    light: darkModeColors.primaryAlt.tint,
    dark: darkModeColors.primaryAlt.shade,
    contrastText: darkModeColors.white,
  },
  secondary: {
    light: darkModeColors.secondary.tint,
    main: darkModeColors.secondary.main,
    dark: darkModeColors.secondary.shade,
    contrastText: darkModeColors.white,
  },
  tertiary1: {
    main: darkModeColors.tertiary1.main,
    light: darkModeColors.tertiary1.tint,
    dark: darkModeColors.tertiary1.shade,
    contrastText: darkModeColors.white,
  },
  tertiary2: {
    main: darkModeColors.tertiary2.main,
    light: darkModeColors.tertiary2.tint,
    dark: darkModeColors.tertiary2.shade,
    contrastText: darkModeColors.white,
  },
  tertiary3: {
    main: darkModeColors.tertiary3.main,
    light: darkModeColors.tertiary3.tint,
    dark: darkModeColors.tertiary3.shade,
    contrastText: darkModeColors.white,
  },
  error: {
    light: alpha(darkModeColors.error, 0.85),
    main: darkModeColors.error,
    dark: darken(darkModeColors.error, 0.2),
    contrastText: darkModeColors.white,
  },
  success: {
    light: alpha(darkModeColors.success, 0.85),
    main: darkModeColors.success,
    dark: darken(darkModeColors.success, 0.2),
    contrastText: darkModeColors.white,
  },
  info: {
    light: alpha(darkModeColors.info, 0.85),
    main: darkModeColors.info,
    dark: darken(darkModeColors.info, 0.2),
    contrastText: darkModeColors.white,
  },
  warning: {
    light: alpha(darkModeColors.warning, 0.85),
    main: darkModeColors.warning,
    dark: darken(darkModeColors.warning, 0.2),
    contrastText: darkModeColors.white,
  },
  text: {
    primary: darkModeColors.black,
    secondary: lighten(darkModeColors.primary.shade, 0.4),
    disabled: lighten(darkModeColors.black, 0.6),
  },
  background: {
    paper: darkModeColors.white,
    default: darkModeColors.uiSurface,
  },
  white: {
    5: alpha(lightModeColors.white, 0.05),
    10: alpha(lightModeColors.white, 0.1),
    25: alpha(lightModeColors.white, 0.25),
    50: alpha(lightModeColors.white, 0.5),
    60: alpha(lightModeColors.white, 0.6),
    80: alpha(lightModeColors.white, 0.8),
    100: lightModeColors.white,
  },
  trueWhite: {
    5: alpha(lightModeColors.trueWhite, 0.02),
    10: alpha(lightModeColors.white, 0.1),
    30: alpha(lightModeColors.white, 0.3),
    50: alpha(lightModeColors.white, 0.5),
    70: alpha(lightModeColors.white, 0.7),
    100: lightModeColors.white,
  },

  black: {
    5: alpha(lightModeColors.black, 0.05),
    10: alpha(lightModeColors.black, 0.1),
    25: alpha(lightModeColors.black, 0.25),
    50: alpha(lightModeColors.black, 0.5),
    60: alpha(lightModeColors.black, 0.6),
    80: alpha(lightModeColors.black, 0.8),
    100: lightModeColors.black,
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  contrastThreshold: 3,
  tonalOffset: 0.5,
  shadows: {
    success: '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
    error: '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
    info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
    primary: '0px 1px 4px rgba(112, 99, 192, 0.25), 0px 3px 12px 2px rgba(112, 99, 192, 0.35)',
    warning: '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
    card: '0px 0px 2px #6A7199',
    cardSm: '0px 0px 2px #6A7199',
    cardLg:
      '0 0rem 14rem 0 rgb(255 255 255 / 20%), 0 0.8rem 2.3rem rgb(111 130 156 / 3%), 0 0.2rem 0.7rem rgb(17 29 57 / 15%)',
  },
};
