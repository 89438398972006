import React from 'react';
import { create } from 'zustand';

export interface DrawerState {
  isDrawerOpen: boolean[] | null;
  drawerDirection: Array<'left' | 'right' | 'top' | 'bottom'> | null;
  drawerWidth?: number; //in vw
  drawerContent: React.ReactNode[] | null;
  openDrawer: (options: DrawerOptions) => void;
  closeDrawer: () => void;
}

interface DrawerOptions {
  drawerDirection?: 'left' | 'right' | 'top' | 'bottom';
  drawerContent: React.ReactNode;
}

/**
 * Represents the state and actions related to the drawer.
 */
export const useDrawerStore = create<DrawerState>((set) => ({
  /**
   * Indicates whether the drawer is currently open or closed.
   */
  isDrawerOpen: null,

  /**
   * The direction in which the drawer opens.
   */
  drawerDirection: null,

  /**
   * The width of the drawer.
   */
  drawerWidth: 40,

  /**
   * The content to be displayed in the drawer.
   */
  drawerContent: null,

  /**
   * Opens the drawer with the specified options.
   * @param {DrawerOptions} options - The options for opening the drawer.
   */
  openDrawer: (options: DrawerOptions) =>
    set(({ drawerContent, isDrawerOpen, drawerDirection }) => {
      return {
        drawerContent: drawerContent?.concat(options.drawerContent) ?? [options.drawerContent],
        isDrawerOpen: isDrawerOpen?.concat(true) ?? [true],
        drawerDirection: drawerDirection?.concat(options.drawerDirection ?? 'right') ?? ['right'],
      };
    }),

  /**
   * Closes the drawer.
   */
  closeDrawer: () =>
    set(({ drawerContent, isDrawerOpen, drawerDirection }) => {
      return {
        drawerContent: drawerContent?.slice(0, -1) ?? null,
        isDrawerOpen: isDrawerOpen?.slice(0, -1) ?? null,
        drawerDirection: drawerDirection?.slice(0, -1) ?? null,
      };
    }),
}));
