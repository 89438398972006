import { FC } from 'react';
import defineAbilityFor from 'src/config/ability';
import { AbilityContext } from './canContext';
import { useCompanyStore } from 'src/store/company/companyStore';

interface IAbilityProvider {
  children: JSX.Element | JSX.Element[];
}

export const AbilityProvider: FC<IAbilityProvider> = ({ children }: IAbilityProvider) => {
  const activeCompany = useCompanyStore((state) => state.activeCompany);
  const ability = defineAbilityFor(activeCompany?.role?.name ?? 'guest');

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};
