import { Button, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useAuth } from 'src/features/authentication';
import Icon from '../SVGIcons';
// import Icon from '../SVGIcons';
import { ViewProfile } from 'src/features/user/ViewProfile';
import { useDrawerStore } from 'src/store/drawer/drawerStore';

const UserInfo = () => {
  const { user } = useAuth();
  const closeDrawer = useDrawerStore((state) => state.closeDrawer);
  const openDrawer = useDrawerStore((state) => state.openDrawer);

  const handleEditAppointment = () => {
    openDrawer({
      drawerContent: <ViewProfile handleCloseDrawer={closeDrawer} />,
      drawerDirection: 'right',
    });
  };

  return (
    <Paper
      variant='elevation'
      sx={{
        paddingY: '12.5px',
        paddingX: '8px',
        border: (theme) => `1px solid ${theme.palette.primary.light}`,
        borderRadius: (theme) => theme.general.borderRadius,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Stack direction='column' spacing={1.5} width={'80%'}>
        <Stack spacing={0.1}>
          <Typography lineHeight='normal' variant='body1' fontWeight='bold' textAlign='left'>
            {user?.displayName}
          </Typography>
          {user?.displayName !== user?.email && (
            <Tooltip placement='right' title={user?.email}>
              <Typography
                variant='body1'
                textAlign='left'
                color='#BABEC6'
                overflow='hidden'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
                lineHeight='normal'
              >
                {user?.email}
              </Typography>
            </Tooltip>
          )}
        </Stack>
      </Stack>

      <Button
        onClick={handleEditAppointment}
        variant='outlined'
        sx={{
          padding: '0px',
          width: '35px',
          height: '35px',
          minWidth: '35px',
        }}
      >
        <Icon name='AngleDoubleIcon' />
      </Button>
    </Paper>
  );
};

export default UserInfo;
