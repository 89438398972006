import { useFeature } from 'src/hooks/useFeature';
import { FeatureFlagNames } from 'src/models/featureFlags';

interface FeatureFlagGuardProps {
  featureFlag: FeatureFlagNames;
  children: JSX.Element;
  fallback?: JSX.Element | null;
  reverse?: boolean;
}

/**
 * FeatureFlagGuard component.
 *
 * This component renders its children only if the specified feature flag is enabled.
 * It can also render a fallback component if the feature flag is disabled.
 *
 * Read the Readme in this folder for more information
 *
 * @component
 * @example
 * // Render the children if the 'myFeatureFlag' is enabled
 * <FeatureFlagGuard featureFlag="myFeatureFlag">
 *   <MyComponent />
 * </FeatureFlagGuard>
 *
 * @param {FeatureFlagGuardProps} props - The component props.
 * @param {string} props.featureFlag - The name of the feature flag to check.
 * @param {React.ReactNode} props.children - The children to render if the feature flag is enabled.
 * @param {React.ReactNode} [props.fallback=null] - The fallback component to render if the feature flag is disabled.
 * @param {boolean} [props.reverse=false] - If true, the fallback component will be rendered when the feature flag is enabled.
 * @returns {React.ReactNode} The rendered component.
 */
export const FeatureFlagGuard: React.FC<FeatureFlagGuardProps> = ({
  featureFlag,
  children,
  fallback = null,
  reverse = false,
}) => {
  const hasFeature = useFeature(featureFlag);

  if (reverse && hasFeature) {
    return fallback;
  }

  if (!reverse && hasFeature === false) {
    return fallback;
  }

  return children;
};
