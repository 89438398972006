import { alpha, Box, lighten, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { BaseDialog } from 'src/components/dialogs/BaseDialog';
import { BaseDrawer } from 'src/components/dialogs/BaseDrawer';
import appConfig from 'src/config/appConfig';
import Sidebar from './Sidebar';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background: theme.palette.mode === 'dark' ? theme.palette.trueWhite[5] : theme.palette.primary.light,
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.palette.primary.main, 0.7),
                    0.15,
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(theme.palette.common.black, 0.1)}, 0px 5px 12px -4px ${alpha(
                    theme.palette.common.black,
                    0.05,
                  )}`,
          },
        }}
      >
        {/* <Header /> */}
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            [theme.breakpoints.up(appConfig.breakMobileView)]: {
              ml: `${theme.sidebar.width}`,
            },
          }}
        >
          <Box display='block'>
            <BaseDrawer />
            <BaseDialog />
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
