// declare module '@mui/material/styles' {
//   interface BreakpointOverrides {
//     mobile: true;
//     tablet: true;
//     xs: false;
//   }
// }

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1840,
  },
  unit: 'px',
};
