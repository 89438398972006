/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { initializeApp } from 'firebase/app';
import {
  applyActionCode,
  AuthError,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { fetchAndActivate, getRemoteConfig, getValue, Value } from 'firebase/remote-config';
import React, { createContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import { UserAuthState, useUserAuthStore } from 'src/store/userAuth/userAuthStore';
import { AuthContextInterface } from '../../context/AuthContextInterface';
import firebaseConfig from './config/firebaseConfig';
import remoteConfigDefaults from './remoteConfig/remote_config_defaults.json';
import { useQuery } from '@tanstack/react-query';
import { usersKeys } from 'src/services/tanstackQuery/queries/users/users.keys';
import { getCurrentUser } from 'src/services/tanstackQuery/queries/users/users.service';
import { useCompanyStore } from 'src/store/company/companyStore';

const app = initializeApp(firebaseConfig.config);
const remoteConfig = getRemoteConfig(app);

// TODO: change fetch time for production ready app

remoteConfig.defaultConfig = remoteConfigDefaults;
remoteConfig.settings.minimumFetchIntervalMillis = 0;

fetchAndActivate(remoteConfig)
  .then(() => {
    console.log('Remote config fetched and activated');
  })
  .catch((err) => {
    toast.error(err);
  });

export type FlagKeys = keyof typeof remoteConfigDefaults;

export const getConfigValue = (key: FlagKeys): Value => {
  return getValue(remoteConfig, key);
};

export const FirebaseAuthContext = createContext<AuthContextInterface>({
  user: null,
  loading: false,
  error: undefined,
  isAuthenticated: false,
  getTokens: () => new Promise((resolve) => null),
  loginWithEmailAndPassword: (email: string, password: string) => new Promise((resolve) => { }),
  loginWithCustomToken: (token: string) => new Promise((resolve) => { }),
  loginWithGoogle: () => new Promise((resolve) => { }),
  register: (email: string, password: string) => new Promise((resolve) => { }),
  logout: (redirect?: boolean) => new Promise((resolve) => { }),
  refreshToken: () => new Promise((resolve) => { }),
  verifyEmail: () => new Promise((resolve) => { }),
  handleVerifyEmail: () => new Promise((resolve) => { }),
  resetPassword: (email: string) => new Promise((resolve) => { }),
  setNewPassword: (oobCode: string, newPassword: string) => new Promise((resolve) => { }),
  emailVerified: false,
  authHook: undefined,
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const FirebaseAuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = getAuth(app);
  const [user, loading, error] = useAuthState(auth);
  const setUserState = useUserAuthStore((state) => state.setUserState);
  const setDbUserState = useUserAuthStore((state) => state.setDbUser);
  const setCompanies = useCompanyStore((state) => state.setCompanies);
  const setActiveCompany = useCompanyStore((state) => state.setActiveCompany);

  // const [getDBUser, { isSuccess }] = useLazyGetCurrentUserForPracticeQuery();

  // const { isSuccess } = useQuery({
  //   queryKey: usersKeys.getCurrentUser(),
  //   queryFn: () => getCurrentUser(),
  //   enabled: !!run,
  // });

  function getAndSetDbUser() {

    // if (!user) return;

    // if (isSuccess) return;

    getCurrentUser()
      .then((resp) => {

        console.log('getCurrentUser', resp);

        setDbUserState(resp.data ? resp.data : null);
        setCompanies(resp.data?.userRoles ?? []);

        const firstRole = resp?.data?.userRoles[0];
        if (firstRole) {
          setActiveCompany(firstRole);
        }

      })
      .catch((err) => {
        return;
      });
  }

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      setUserState({
        user,
        accessToken: await user.getIdToken(),
        refreshToken: user.refreshToken,
        userStatus: 'authenticated',
        error: null,
      });
      getAndSetDbUser();
    }
  });

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
      const firebaseUser = await signInWithEmailAndPassword(auth, email, password);
      const user: UserAuthState = {
        user: firebaseUser.user,
        userStatus: 'authenticated',
        accessToken: await firebaseUser.user.getIdToken(),
        refreshToken: firebaseUser.user.refreshToken,
        error: null,
      };
      setUserState(user);
    } catch (error: any) {
      handleError(error);
    }
  };

  const loginWithCustomToken = async (token: string) => {
    try {
      const firebaseUser = await signInWithCustomToken(auth, token);
      const user: UserAuthState = {
        user: firebaseUser.user,
        userStatus: 'authenticated',
        accessToken: await firebaseUser.user.getIdToken(),
        refreshToken: firebaseUser.user.refreshToken,
        error: null,
      };
      setUserState(user);
    } catch (error: any) {
      handleError(error);
    }
  };

  const loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error: any) {
      handleError(error);
    }
  };

  const register = async (email: string, password: string) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      handleError(error);
    }
  };

  const logout = async (redirect?: boolean) => {
    try {
      logoutFirebase(redirect);
    } catch (error: any) {
      handleError(error);
    }
  };

  const refreshToken = async () => {
    try {
      const res = await refreshFirebaseToken();
    } catch (error: any) {
      handleError(error);
    }
  };

  const getTokens = async () => {
    const currentUser = auth.currentUser;
    try {
      return {
        accessToken: (await currentUser?.getIdToken(true)) ?? null,
        refreshToken: currentUser?.refreshToken ?? null,
      };
    } catch (error: any) {
      handleError(error);
      return null;
    }
  };

  const verifyEmail = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        await sendEmailVerification(currentUser);
      } catch (error: any) {
        handleError(error);
      }
    }
  };

  const handleVerifyEmail = async (actionCode: string) => {
    try {
      const userEmail = await checkActionCode(auth, actionCode).then((info) => {
        applyActionCode(auth, actionCode);
        return info.data.email;
      });

      await logout(false);

      return { valid: true, email: userEmail };
    } catch (error: any) {
      handleError(error);
    }
    return { valid: false };
  };

  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error: any) {
      handleError(error);
    }
  };

  const setNewPassword = async (oobCode: string, newPassword: string) => {
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleError = (error: AuthError) => {
    toast.error('Error Authenticating...');
    console.log('Authentication Error:', error);
  };

  const emailVerified = user?.emailVerified ?? false;

  const isAuthenticated = user ? true : false;

  const authHook = useAuthState;

  return (
    <FirebaseAuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        error,
        getTokens,
        loginWithEmailAndPassword,
        loginWithCustomToken,
        loginWithGoogle,
        register,
        logout,
        refreshToken,
        verifyEmail,
        handleVerifyEmail,
        resetPassword,
        setNewPassword,
        emailVerified,
        authHook,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export const refreshFirebaseToken = async (forceRefresh = false) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('Cannot get new token');
    }
    return {
      accessToken: await currentUser?.getIdToken(forceRefresh),
      refreshToken: currentUser?.refreshToken,
    };
  } catch (error) {
    return {
      refreshToken: null,
      accessToken: null,
    };
  }
};

export const logoutFirebase = async (redirect?: boolean) => {
  const auth = getAuth();
  await signOut(auth);
  useUserAuthStore.getState().resetUserState();
  if (redirect ?? true) window.location.href = '/auth/login';
};
