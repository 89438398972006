import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { IUserRoleWithCompanyAndRole } from 'src/services/tanstackQuery/queries/users/getCurrentUserDto';

export type CompanyState = {
  companies: IUserRoleWithCompanyAndRole[];
  activeCompany: IUserRoleWithCompanyAndRole | null;
};

export interface CompanyActionsState {
  setCompanies: (companies: IUserRoleWithCompanyAndRole[]) => void;
  setActiveCompany: (company: IUserRoleWithCompanyAndRole | null) => void;
  resetCompanyState: () => void;
  setRoleForActiveCompany: (role: { id: string; name: string }) => void;
}

const initialState: CompanyState = {
  companies: [],
  activeCompany: null,
};

export const useCompanyStore = create<CompanyState & CompanyActionsState>()(
  persist(
    (set) => ({
      ...initialState,

      setCompanies: (companies: IUserRoleWithCompanyAndRole[]) => set({ companies: companies }),

      setRoleForActiveCompany: (role: { id: string; name: string }) =>
        set(function (state: CompanyState) {
          return { activeCompany: state.activeCompany ? { ...state.activeCompany, role: role } : null };
        }),

      setActiveCompany: (company: IUserRoleWithCompanyAndRole | null) =>
        set(function (state: CompanyState) {
          if (company === null) return { activeCompany: state.companies[0] };

          return { activeCompany: company };
        }),

      resetCompanyState: () => set(initialState),
    }),
    { name: 'companyStore' },
  ),
);
