import { useEffect, useState } from 'react';
import RemoteConfigWrapper from 'src/lib/remoteConfigWrapper';
import { FeatureFlagNames, FeatureFlags, featureFlagsSchema } from 'src/models/featureFlags';

/**
 * Custom hook to retrieve the value of a feature flag.
 * @param feature - The name of the feature flag.
 * @returns The boolean value of the feature flag.
 */
export const useFeature = (feature: FeatureFlagNames): boolean => {
  //This is the service that will get values from the remote config
  const ffc = new RemoteConfigWrapper(); // Here we can initialize a different service
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({});

  const prep = async () => {
    await ffc.initialize(); // we need to init it every time to simulate realtime fetches
    const featureFlag = featureFlagsSchema.safeParse(ffc.getBoolean(feature));
    if (featureFlag.success) {
      setFeatureFlags(featureFlag.data);
    } else {
      console.error('Error parsing feature flag:', featureFlag.error);
    }
  };

  useEffect(() => {
    prep();
  }, [feature]);

  return Object.hasOwn(featureFlags, feature) ? featureFlags[feature]?.isEnabled ?? false : false;
};
